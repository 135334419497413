.inputLabel {
  font-size: 16px;
  color: var(--text-black-color);
  line-height: 1;
  /* padding-left: 10px; */
  margin-bottom: 5px;
}
.inputStyling {
  border-radius: 5px;
  background-color: var(--input-bg-color) !important;
  border: 1px solid var(--border-color);
  min-height: 45px;
  color: var(--text-black);
  padding: 8px 12px;
  height: 40px;
  box-shadow: none;
}
