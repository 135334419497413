.filledCheckBox {
  display: flex;
  align-items: center;
}
.filledCheckBox .customLabel {
  width: 100%;
  border-bottom: 1px solid #d8d8d8;
  color: 636363;
  margin-left: 10px;
  padding: 9px 0;
  font-size: 14px;
}

.checkbox {
  width: 13px;
  height: 12px;
  border-radius: 3px;
  transition: box-shadow 0.3s;
  background: transparent;
  border: 1px solid gray;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
}

.checkbox:checked {
  background-color: #6ac567;
  border: 1px solid#6ac567;
}
/*--multi checkbox --*/
.filledCheckBox .multiCheckBox {
  border-left: 1px solid #dadada;
  display: flex;
  align-items: center;
}
.filledCheckBox .multiCheckBox .checkbox {
  width: 13px !important;
  height: 12px !important;
  margin-inline: 10px;
}
.filledCheckBox .multiCheckBox .multiLabel {
  font-size: 14px;
}






