.closeButton {
  position: absolute;
  top: 20px;
  right: 30px;
  cursor: pointer;
}
.btnClose {
  background-color: #aeaeae !important;
  border-radius: 50%;
  opacity: 1 !important;
  background-image: none !important;
  padding: 5px;
}
.btnClose i {
  color: white;
}
