/*custom-checkbox*/
.checkboxCustom {
  opacity: 0;
  position: absolute;
}
.checkboxCustom,
.checkboxCustomLabel {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.checkboxCustomLabel,
.radio-custom-label {
  position: relative;
}
.checkboxCustom + .checkboxCustomLabel:before {
  content: "";
  background: #fff;
  border: 1px solid #ddd;
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 15px;
  line-height: 15px;
  /* padding: 2px; */
  margin-right: 0;
  text-align: center;
}

.checkboxCustom:checked + .checkboxCustomLabel:before {
  content: "✓";
  font-family: "FontAwesome";
  background: green;
  color: #fff;
}
.checkboxCustom:checked + .red.checkboxCustomLabel:before {
  background: #bf1e2e;
}
.checkboxCustom:checked + .yellow.checkboxCustomLabel:before {
  background: yellow;
}
.checkboxCustom:checked + .blue.checkboxCustomLabel:before {
  background: #005cc8;
}
.checkboxCustom:checked + .orange.checkboxCustomLabel:before {
  background: #bf1e2e;
}
.checkbox-custom:focus + .checkboxCustomLabel {
  /*outline: 1px solid #ddd; !* focus style *!*/
}
/*radio*/
.radioButton {
  padding: 2px;
  margin-top: 10px;
  margin-right: 5px;
}
