.procedure-view-container {
  margin-top: 10px;
  max-height: 242px;
  overflow-y: auto;
}

.procedure-view-table {
  width: 100%;
  border-collapse: collapse;
}

.procedure-view-th {
  background-color: #f2f2f2;
  font-size: 12px;
  padding: 5px;
  border: 1px solid #dddddd;
}

/* .procedure-view-td {
  text-align: left;
  padding: 5px;
  font-size: 12px;
  border: 1px solid #dddddd;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  position: relative;
  cursor: pointer;
}

.tooltip {
  display: none;
  position: absolute;
  z-index: 100;
  border: 1px;
  background-color: white;
  border: 1px solid green;
  padding: 3px;
  color: green;
  top: 20px;
  left: 20px;
}

.procedure-view-td:hover span.tooltip {
  display: block;
} */

.procedure-view-td {
  text-align: left;
  padding: 5px;
  font-size: 12px;
  border: 1px solid #dddddd;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  position: relative;
  cursor: pointer;
}

.procedure-text-center {
  text-align: center !important;
}
